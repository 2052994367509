
// npm
import React from 'react'
import PropTypes from 'prop-types'

// material-ui
import TextField from '@material-ui/core/TextField'


let MultiLineTextEditor = (props) =>
{
	const {lineCount, maxLineCount, inputProps={}, ...additionalProps} = props;

	const editorProps = {
		multiline: true,
		fullWidth: true,
		rows: lineCount,
		margin: "normal",
		variant: "outlined",
		rowsMax: maxLineCount,
		inputProps: {
			spellCheck: false,
			onPaste: (e) => { e.preventDefault(); },
			onDrop: (e) => { e.preventDefault(); },
			...inputProps
		}
	}

	return <TextField {...editorProps} {...additionalProps}/>;
}

MultiLineTextEditor.propTypes = {
	onChange: PropTypes.func,
	lineCount: PropTypes.number,
	value: PropTypes.string.isRequired,
	disabled: PropTypes.bool.isRequired,
	maxLineCount: PropTypes.number.isRequired
}

MultiLineTextEditor.defaultProps = {
	maxLineCount: 30,
	disabled: false,
	value: ""
}


// EXPORT
// ------------------------------------------------------------------
export {MultiLineTextEditor}