
// npm
import React from 'react'
import PropTypes from 'prop-types'
import {Editor} from '@tinymce/tinymce-react'

// material-ui
import {styles} from './styles'
import withStyles from '@material-ui/core/styles/withStyles'
import CircularProgress from '@material-ui/core/CircularProgress'

// constants
import {fontFormats} from './font-formats'


const TAB_KEYCODE = 9;


// RichTextEditor (not connected to styles)
// ---------------------------------------------------------------------

class RichTextEditor extends React.Component
{
	constructor(props)
	{
		super(props);
		this.state = {loaded: false}

		this.setLoaded = () => { this.setState({loaded: true}); }
		this.setEditorMode = () => this.editor.setMode(this.EditorMode);

		const onLoad = () => {
			this.setLoaded();
			this.setEditorMode();
		}

		const tryAllowTab = (event) => {
			if (event.keyCode === TAB_KEYCODE) {
				this.editor.execCommand(event.shiftKey ? 'Outdent' : 'Indent');
				event.preventDefault();
				return false;
			}
		}

		let plugins='link image paste lists';
		if (props.showWordCount){
			plugins+=' wordcount'
		}

		this.initConfig = {
			menubar: false,
			branding: false,
			elementpath: false,
			height: props.height,
			gecko_spellcheck: false,
			plugins,
			paste_preprocess: (plugin, args) => { args.content = ''; },
			setup: (editor) => {
				this.editor = editor;
				editor.on('LoadContent', onLoad);
				editor.on('KeyDown', tryAllowTab);

				if (props.onFocus) {
					editor.on('focus', props.onFocus);
				}
				if (props.onBlur) {
					editor.on('blur', props.onBlur);
				}
			},
			font_formats: fontFormats,
			content_css: ['https://fonts.googleapis.com/css?family=Dancing+Script:400,700'],
			content_style: 'p { margin-top: 0px !important; margin-bottom: 0px !important; }',
			toolbar: 'undo redo | bold italic underline | outdent indent | alignleft aligncenter alignright | numlist bullist | fontselect fontsizeselect'
		}
	}

	componentDidUpdate(previousProps)
	{
		if (previousProps.disabled !== this.props.disabled) {
			this.setEditorMode();
		}
	}

	render()
	{
		// Note: The editor must be rendered at all times. 
		// When tinyMCE editor is set up, it will invoke a callback which we can
		// use to remove the loading spinner.

		return (
			<React.Fragment>
				{this.Editor}
				{this.state.loaded ? null : this.Spinner}
			</React.Fragment>
		);
	}

	get Editor()
	{
		const props = {
			key: this.state.key,
			init: {...this.initConfig, readonly: this.props.disabled ? 1 : 0},
			onEditorChange: this.props.onChange,
			// onChange: this.props.onChange,
			initialValue: this.props.initialValue
		}

		//console.log({RICH_PROPS: props})
		return <Editor {...props}/>;
	}

	get Spinner()
	{
		const {classes} = this.props;

		return (
			<div className={classes.loadingDiv}>
				<CircularProgress className={classes.progress} color="secondary"/>
			</div>
		);
	}

	get EditorMode()
	{
		return this.props.disabled ? 'readonly' : 'design';
	}
}

RichTextEditor.propTypes = {
	height: PropTypes.number,
	onChange: PropTypes.func,
	onFocus: PropTypes.func,
	onBlur: PropTypes.func,
	disabled: PropTypes.bool.isRequired,
	classes: PropTypes.object.isRequired,
	initialValue: PropTypes.string.isRequired
}

RichTextEditor.defaultProps = {
	height: 400,
	disabled: false,
	initialValue: ""
}


// RichTextEditor (connected to styles)
// ---------------------------------------------------------------------

RichTextEditor = withStyles(styles)(RichTextEditor);


// EXPORT
// ---------------------------------------------------------------------
export {RichTextEditor}