const CALCULATOR_BUTTONS = [
  ['C', '<', '@', '+'],
  ['7', '8', '9', '*'],
  ['4', '5', '6', '-'],
  ['1', '2', '3', '/'],
  ['0', '.', '=']
];

const OPERANDS = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '.'];

const OPERATORS = ['+', '-', '/', '*'];

const CALCULATOR_EVALUATION = {
  scientific: 0,
  leftToRight: 1
}

export { CALCULATOR_BUTTONS, CALCULATOR_EVALUATION, OPERANDS, OPERATORS };
