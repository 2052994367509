import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';

const styles = theme => ({
  button: {
    margin: theme.spacing.unit,
    height: "32px"
  },
  singleButton: {
    minWidth: 32
  },
  doubleButton: {
    minWidth: 64 + theme.spacing.unit*2
  },
  plusMinus: {
    fontFamily: 'Courier New, Courier, monospace'
  }
});

const CalculatorButton = ({ value, onSelect, classes }) => {
  if (value === "") {
      const styles = [classes.button, classes.singleButton];
      return <div className={styles.join(" ")} />;
  }

  let display = value;
  const styles = [classes.button];
  styles.push(value === '=' ? classes.doubleButton : classes.singleButton);
  if (value === '@') {
    styles.push(classes.plusMinus);
    display = '\xB1';
  }
  if (value === '<') {
    styles.push(classes.plusMinus);
    display = '\u2190';
  }
  if (value === '/') {
    styles.push(classes.plusMinus);
    display = '\u00F7';
  }  
  if (value === '*') {
    styles.push(classes.plusMinus);
    // display = 'x';
    display = '\u00d7';
  }
  
  return (
    <Button
      className={styles.join(' ')}
      variant="contained"
      color="primary"
      size="small"
      onClick={() => {
        if (value) onSelect(value);
      }}
    >
      {display}
    </Button>
  );
};

export default withStyles(styles)(CalculatorButton);
