// npm
import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

// react
import { AppPageModifier } from "components/pages/app-page-modifier";

// redux (selectors)
import { getOrgId } from "redux/reducers/session/client/selectors";
import { getClientSessionData } from "redux/reducers/session/selectors";
import { getSessionData } from "redux/reducers/selectors";

// redux (action-types)
import {
    SET_NOTIFY_IMMEDIATELY_ON_LOST_CONNECTION,
    SET_CALCULATOR_EVALUATION_MODE,
    SET_CONFIRM_DOB_MODE,
    SET_CONFIRM_LEARNER_IDENTIFIER_MODE,
    SET_HIDE_SECTION_NAMES_IN_PLAYER,
    SET_REMEMBER_SCENARIO,
    SET_UPLOAD_FILE_TYPES,
    SET_RESULT_FEEDBACK_MASK,
    SET_INTEGRITY_ADVOCATE_APP_ID,
    SET_ORG_FULL_SCREEN_EXAM    

} from "redux/reducers/settings/client/action-types";

// redux (actions)
import { setNetworkError } from "components/actions";

// utils
import { settingsApi } from "libs/api/interface/api-settings";

const saveCalculatorEvaluationMode = (value) => ({
    type: SET_CALCULATOR_EVALUATION_MODE,
    value,
});

const saveConfirmDobMode = (value) => ({
    type: SET_CONFIRM_DOB_MODE,
    value,
});

const saveConfirmLearnerIdentifierMode= (value) => ({
    type: SET_CONFIRM_LEARNER_IDENTIFIER_MODE,
    value,
});

const saveHideSectionNamesInPlayer = (value) => ({
    type: SET_HIDE_SECTION_NAMES_IN_PLAYER,
    value,
});

const saveRememberScenario = (value) => ({
    type: SET_REMEMBER_SCENARIO,
    value,
});

const saveNotifyImmediatelyOnLostConnection = (value) => ({
    type: SET_NOTIFY_IMMEDIATELY_ON_LOST_CONNECTION,
    value,
});

const saveUploadFileTypes = (value) => ({
    type: SET_UPLOAD_FILE_TYPES,
    value,
});

const saveResultFeedbackMask = (value) => ({
    type: SET_RESULT_FEEDBACK_MASK,
    value,
});

const saveIntegrityAdvocateAppID = (value) => ({
    type: SET_INTEGRITY_ADVOCATE_APP_ID,
    value,
});

const saveOrgFullScreenExam = (value) => ({
    type: SET_ORG_FULL_SCREEN_EXAM,
    value,
});

class ClientSettingsFetcher extends React.Component {
    componentDidMount() {
        this.fetchSettings(this.props.orgId)
            .then(this.props.onSuccess)
            .catch(this.props.onError);
    }

    async fetchSettings(orgId) {
        let response = await settingsApi.getCalculatorEvaluationMode(orgId);
        this.props.setCalculatorEvaluationMode(response);

        response = await settingsApi.getConfirmDobMode(orgId);
        this.props.setConfirmDobMode(response);

		response = await settingsApi.getHideSectionNamesInPlayer(orgId);
        this.props.setHideSectionNamesInPlayer(response);

        response = await settingsApi.getConfirmLearnerIdentifierMode(orgId);
        this.props.setConfirmLearnerIdentifierMode(response);        

        response = await settingsApi.getRememberScenario(orgId);
        this.props.setRememberScenario(response);

        response = await settingsApi.getNotifyImmediatelyOnConnectionLoss(
            orgId
		);
        this.props.setNotifyImmediatelyOnLostConnection(response);

        response = await settingsApi.getUploadFileTypes(orgId);
        this.props.setUploadFileTypes(response); 
        
        response = await settingsApi.getResultFeedbackMask(orgId);
		this.props.setResultFeedbackMask(response);

        response = await settingsApi.getIntegrityAdvocateAppID(orgId);
		this.props.setIntegrityAdvocateAppID(response);

        response = await settingsApi.getOrgFullScreenExam(orgId);
        this.props.setOrgFullScreenExam(response);
    }

    render() {
        const appBarProps = {
            title: "Loading settings",
            loadingTitle: true,
            logo: true,
        };

        return <AppPageModifier appBarProps={appBarProps} loading />;
    }
}

ClientSettingsFetcher.propTypes = {
    orgId: PropTypes.number.isRequired,
    setCalculatorEvaluationMode: PropTypes.func.isRequired,
    setNotifyImmediatelyOnLostConnection: PropTypes.func.isRequired,
    onError: PropTypes.func.isRequired,
};

const mapStoreToProps = (store) => ({
    orgId: getOrgId(getClientSessionData(getSessionData(store))),
});

const mapDispatchToProps = (dispatch, { onSuccess }) => ({
    onError: () => dispatch(setNetworkError(true)),
    setNotifyImmediatelyOnLostConnection: (response) => {
        const parsedResponse = JSON.parse(response);
        dispatch(saveNotifyImmediatelyOnLostConnection(parsedResponse.value));
    },
    setCalculatorEvaluationMode: (response) => {
        const parsedResponse = JSON.parse(response);
        dispatch(saveCalculatorEvaluationMode(parsedResponse.value));
    },
    setConfirmDobMode: (response) => {
        const parsedResponse = JSON.parse(response);
        dispatch(saveConfirmDobMode(parsedResponse.value));
    },
    setConfirmLearnerIdentifierMode: (response) => {
        const parsedResponse = JSON.parse(response);
        dispatch(saveConfirmLearnerIdentifierMode(parsedResponse.value));
    },        
    setHideSectionNamesInPlayer: (response) => {
        const parsedResponse = JSON.parse(response);
        dispatch(saveHideSectionNamesInPlayer(parsedResponse.value));
    },
    setRememberScenario: (response) => {
        const parsedResponse = JSON.parse(response);
        dispatch(saveRememberScenario(parsedResponse.value));
    },
    setUploadFileTypes: (response) => {
        const parsedResponse = JSON.parse(response);
        dispatch(saveUploadFileTypes(parsedResponse.value));
    },
	setResultFeedbackMask: (response) => {
		const parsedResponse = JSON.parse(response);
		dispatch(saveResultFeedbackMask(parsedResponse.value));
	},
	setIntegrityAdvocateAppID: (response) => {
		const parsedResponse = JSON.parse(response);
		dispatch(saveIntegrityAdvocateAppID(parsedResponse.value));
	},
    setOrgFullScreenExam: (response) => {
        const parsedResponse = JSON.parse(response);
        dispatch(saveOrgFullScreenExam(parsedResponse.value));
    },                                            
});

ClientSettingsFetcher = connect(
    mapStoreToProps,
    mapDispatchToProps
)(ClientSettingsFetcher);

export { ClientSettingsFetcher };
