
const getMappingsData = (contentData) => contentData.get('mappings');
const getSectionsData = (contentData) => contentData.get('sections');
const getQuestionsData = (contentData) => contentData.get('questions');
const getResourceUrl = (contentData) => contentData.get('resourceUrl');
const getResumedQuestionNumber = (contentData) => contentData.get('initialPage');
const getCurrentQuestionId = (contentData) => contentData.get('currentQuestionId');
const getQuestionIdSetFromQuestion  = (contentData) => contentData.get('questionIdSetFromQuestion');


export {
	getMappingsData, 
	getSectionsData, 
	getQuestionsData,
	getResourceUrl,
	getCurrentQuestionId,
	getResumedQuestionNumber,
	getQuestionIdSetFromQuestion
}