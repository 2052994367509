
// npm
import React from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'

// redux
import * as ACTIONS from './actions'
import {getClientSessionData} from 'redux/reducers/session/selectors'
import {getOrgId} from 'redux/reducers/session/client/selectors'
import {getCredentialData} from 'redux/reducers/login/selectors'
import {getLoginData, getSessionData} from 'redux/reducers/selectors'
import {getUsername, getPassword} from 'redux/reducers/login/credentials/selectors'
import {getMessagesData} from 'redux/reducers/selectors'
import {getMessageById} from 'redux/reducers/messages/complex-selectors'

// utils
import {authenticationApi} from 'libs/api/interface/api-authentication'
import {sessionStorageApi, KEYS} from 'libs/browser_storage/session-storage-api'
import {doesOrgNeedLoginToken, doesUserNeedLoginToken, getLoginToken, getStudentLoginResponse, loginTokenErrorMessage} from './login_token'

// constants
import {MESSAGE_IDS} from 'constants/message-ids'


// LoginAuthenticator (not connected to store)
// --------------------------------------------------

const getScreenResolutionData = () =>
{
	const {screen, devicePixelRatio} = window;

	const cssWidth = screen.width;
	const cssHeight = screen.height;

	const screenWidth = cssWidth * devicePixelRatio; // see https://coderwall.com/p/ygcyha/how-to-get-real-mobile-css-resolutions-for-responsive-design
	const screenHeight = cssHeight * devicePixelRatio;

	return {
		css: {width: cssWidth, height: cssHeight},
		screen: {width: screenWidth, height: screenHeight},
		pixelRatio: devicePixelRatio
	}
}

const getScreenResolutionString = () =>
{
	const {css, screen, pixelRatio} = getScreenResolutionData();

	const cssResolution = `${css.width}x${css.height}`;
	const actualResolution = `${screen.width}x${screen.height}`;

	return `[css=${cssResolution}] [screen=${actualResolution}] [pixelRatio=${pixelRatio}]`;
}

class LoginAuthenticator extends React.Component
{
	componentDidMount()
	{
		const payload = {
			username: this.props.u,
			password: this.props.p,
			orgId: this.props.orgId,
			screenRes: getScreenResolutionString(),
			userAgent: navigator.userAgent
		}

		const checkLoginToken = (response) => {
			const parsedResponse = JSON.parse(response);
			const {UserTypeID} = parsedResponse;
			const {orgId} = this.props;

			if (doesUserNeedLoginToken(orgId, UserTypeID)) {

				sessionStorageApi.saveDataTo(KEYS.STUDENT_LOGIN_RESPONSE, {response});

				authenticationApi.checkLoginToken(getLoginToken())
				.then(loginTokenOk, onFailLoginToken);
			}
			else onSuccess(response);
		}

		const loginTokenOk = () => {
			const response = getStudentLoginResponse();
			sessionStorageApi.resetDataFor(KEYS.STUDENT_LOGIN_RESPONSE);
			onSuccess(response);
		}

		const onSuccess = (response) => {
			// 1. Parse response
			const parsedResponse = JSON.parse(response);

			// 2. Extract important properties
			const {access_token, refresh_token} = parsedResponse;
			const {UserLanguageID:userLanguageId, UserID:userId} = parsedResponse;
			const {UserGUID:userGuid, UserFirstName:userFirstName} = parsedResponse;
			const {UserLastName:userLastName} = parsedResponse;
			const {DOB:dateOfBirth} = parsedResponse;
			const {Admin:admin} = parsedResponse;
			const {NINumber:learnerIdentifier} = parsedResponse;
			const {Email: email} = parsedResponse;

			// 3. Save tokens
			this.props.saveTokens(access_token, refresh_token);

			// 4. Save user data
			this.props.createUserSession(userId, userGuid, userFirstName, userLastName, userLanguageId, dateOfBirth, admin, learnerIdentifier, email);
			
			// 5. Final touches
			this.props.onSuccess();
			this.props.clearFormData();
		}

		const onFail = () => {
			this.props.setLoginError(this.props.wrongPasswordText);
			this.props.clearPassword();
			this.props.onFail();
		}

		const onFailLoginToken = () => {
			this.props.setLoginError(loginTokenErrorMessage);
			this.props.clearPassword();
			this.props.onFail();
		}
		
		const {orgId} = this.props;
		if (doesOrgNeedLoginToken(orgId)) {
			sessionStorageApi.resetDataFor(KEYS.STUDENT_LOGIN_RESPONSE);

			authenticationApi.requestAuthentication(payload)
			.then(checkLoginToken, onFail);
		}
		else {
			authenticationApi.requestAuthentication(payload)
			.then(onSuccess, onFail);
		}
	}

	render()
	{
		return null;
	}
}

LoginAuthenticator.propTypes = {
	u: PropTypes.string.isRequired,
	p: PropTypes.string.isRequired,
	orgId: PropTypes.number.isRequired,
	onFail: PropTypes.func.isRequired,
	onSuccess: PropTypes.func.isRequired,
	saveTokens: PropTypes.func.isRequired,
	clearFormData: PropTypes.func.isRequired,
	createUserSession: PropTypes.func.isRequired,
	setLoginError: PropTypes.func.isRequired,
	wrongPasswordText: PropTypes.string.isRequired
}

// LoginAuthenticator (connected to store)
// --------------------------------------------------

const mapStoreToProps = (store) => ({
	u: getUsername(getCredentialData(getLoginData(store))),
	p: getPassword(getCredentialData(getLoginData(store))),
	orgId: getOrgId(getClientSessionData(getSessionData(store))),
	wrongPasswordText: getMessageById(getMessagesData(store))(MESSAGE_IDS.LOGIN.WRONG_PASSWORD),
	invalidLoginTokenText: getMessageById(getMessagesData(store))(MESSAGE_IDS.LOGIN.INVALID_LOGIN_TOKEN),
});

const mapDispatchToProps = (dispatch) => ({
	saveTokens: (access, refresh) => {
		dispatch(ACTIONS.saveTokens({access, refresh}));
	},
	createUserSession: (id, guid, firstName, lastName, languageId, dateOfBirth, admin, learnerIdentifier, email) => {
		const data = {id, guid, firstName, lastName, languageId, dateOfBirth, admin, learnerIdentifier, email};
		dispatch(ACTIONS.createUserSession(data));
	},
	clearFormData: () => {
		dispatch(ACTIONS.clearCredentials());
		dispatch(ACTIONS.clearLoginError());
	},
	clearPassword: () => {
		dispatch(ACTIONS.clearPassword());
	},
	setLoginError: (msg) => {
		dispatch(ACTIONS.setLoginError(msg));
	}
})

LoginAuthenticator = connect(mapStoreToProps, mapDispatchToProps)(LoginAuthenticator);


export {LoginAuthenticator}