import * as math from "mathjs";

import check from "check-types";

import { CALCULATOR_EVALUATION, OPERATORS, OPERANDS } from "./constants";

const isOperand = (value) => {
    return OPERANDS.includes(value);
};

const isOperator = (value) => {
    return OPERATORS.includes(value);
};

const getResult = (value, evaluationType, decimalPlaces = 9) => {
    let result = value;

    if (evaluationType === CALCULATOR_EVALUATION.scientific)
        result = getScientificResult(value, decimalPlaces);
    else if (evaluationType === CALCULATOR_EVALUATION.leftToRight)
        result = getLeftToRightResult(value, decimalPlaces);

    return removeTrailingSpaces(result);
};

const getScientificResult = (value, decimalPlaces) => {
    try {
        const answer = math.eval(value);
        return math.format(answer, {
            precision: decimalPlaces,
            notation: "fixed",
        });
    } catch (e) {
        return undefined;
    }
};

const getLeftToRightResult = (value, decimalPlaces) => {
    const parts = getCalculationParts(value);
    let answer = 0;
    parts.forEach((part) => {
        answer = evaluatePart(answer, part);
    });
    return check.integer(answer) ? "" + answer : answer.toFixed(decimalPlaces);
};

const getCalculationParts = (value) => {
    const parts = [];

    let numberStart = null;
    let sign = "+";
    let previousOperator = "+";

    for (let i = 0; i < value.length; i++) {
        const currentCharacter = value.charAt(i);

        if (isOperand(currentCharacter)) {
            if (!check.assigned(numberStart)) numberStart = i;
        } else if (isOperator(currentCharacter)) {
            if (!check.assigned(numberStart)) {
                sign = currentCharacter;
            } else {
                parts.push({
                    operator: previousOperator,
                    number: getNumberFromValue(
                        value.substring(numberStart, i),
                        sign
                    ),
                });

                previousOperator = currentCharacter;
                sign = "+";
                numberStart = null;
            }
        }
    }

    if (check.assigned(numberStart)) {
        parts.push({
            operator: previousOperator,
            number: getNumberFromValue(
                value.substring(numberStart, value.length),
                sign
            ),
        });
    }
    return parts;
};

const getNumberFromValue = (value, sign) => {
    const answer = value.includes(".") ? parseFloat(value) : parseInt(value);
    return sign === "-" ? -1 * answer : answer;
};

const evaluatePart = (answer, part) => {
    if (part.operator === "+") answer += part.number;
    else if (part.operator === "-") answer -= part.number;
    else if (part.operator === "*") answer *= part.number;
    else if (part.operator === "/") answer /= part.number;

    return answer;
};

const spaceOutResult = (value, result, button = '"') => {
    let spacedResult = "";
    let previousOperator = true;
    let operatorFound = false;

    for (let i = 0; i < value.length; i++) {
        const currentCharacter = value.charAt(i);
        if (isOperator(currentCharacter) && !previousOperator) {
            if (i !== value.length - 1) {
                spacedResult += ` ${currentCharacter} `;
                previousOperator = true;
                operatorFound = true;
            }
        } else {
            previousOperator = false;
            spacedResult += currentCharacter;
        }
    }
    if (operatorFound) {
        spacedResult += ` ${button} ${result} `;
    } else if (button === "}") {
        spacedResult += ` \u221a ${result} `;
    }

    return spacedResult;
};

const plusMinusValue = (value) => {
    if (value === "") return value;

    const lastCharacter = value.substr(value.length - 1);
    let isPositive = true;
    let previous = 0;
    let numberStart = 0;

    if (isOperator(lastCharacter)) return value;
    for (let i = value.length - 1; i >= 0; i--) {
        const currentCharacter = value.charAt(i);
        if (!isOperand(currentCharacter)) {
            if (i === 0) {
                isPositive = currentCharacter === "+";
                numberStart = i + 1;
            } else {
                const previousCharacter = value.charAt(i - 1);
                if (isOperator(previousCharacter)) {
                    isPositive = currentCharacter === "+";
                    numberStart = i + 1;
                    previous = i;
                } else {
                    numberStart = i + 1;
                    previous = i + 1;
                }
            }
            break;
        }
    }

    return (
        value.slice(0, previous) +
        (isPositive ? "-" : "") +
        value.slice(numberStart)
    );
};

const decimalPointAllowed = (value) => {
    for (let i = value.length - 1; i >= 0; i--) {
        const currentCharacter = value.charAt(i);
        if (currentCharacter === ".") return false;
        if (!isOperand(currentCharacter)) break;
    }

    return true;
};

const operatorAllowed = (operator, value) => {
    if (value.length === 0) return true;

    const lastCharacter = value.charAt(value.length - 1);
    if (isOperand(lastCharacter)) return true;
    if (operator === "-") {
        if (value.length > 2) {
            const previousCharacter = value.charAt(value.length - 2);
            if (isOperand(previousCharacter)) return true;
        } else return true;
    }

    return false;
};

const removeTrailingSpaces = (result) => {
    if (check.string(result)) {
        if (result.indexOf(".") !== -1) {
            for (let i = result.length; i >= 0; i--) {
                if (result[i] === ".") return result.substring(0, i);
                else if (isNotZeroNumber(result[i]))
                    return result.substring(0, i + 1);
            }
        }
        return result;
    } else return result.toString();
};

const isNotZeroNumber = (value) => {
    const operands = OPERANDS.filter(
        (operand) => !["0", "."].includes(operand)
    );
    return operands.includes(value);
};

const isSEB = () => {
    return navigator.userAgent.indexOf("SEB") !== -1;
};

const addCalculatorSymbols = (value) => {
    return value.replace(/[*]/gi, "\u00d7").replace(/[/]/gi, "\u00f7");
};

const removeCalculatorSymbols = (value) => {
    return value.replace(/[\u00d7]/gi, "*").replace(/[\u00f7]/gi, "/");
};

const hasCalculatorSymbols = (value, ref) => {
    if (value && (value.indexOf("*") !== -1 || value.indexOf("/") !== -1)) {
        const workingsElement = ref.current;
        const { selectionStart } = workingsElement;

        return { value: addCalculatorSymbols(value), cursor: selectionStart };
    }
    return { value, cursor: null };
};

export {
    isOperand,
    isOperator,
    getResult,
    plusMinusValue,
    spaceOutResult,
    decimalPointAllowed,
    operatorAllowed,
    isSEB,
    addCalculatorSymbols,
    removeCalculatorSymbols,
    hasCalculatorSymbols,
};
