// INFORMATION:

// Simple selectors (selectors.js):
// These work on direct properties of an immutable object in order to return
// the resulting data

// Complex selectors (complex-selectors.js): 
// These utilize selectors from child reducers in order to find the data that 
// must be returned

import check from 'check-types'

import * as CONTENT_SELECTORS from './selectors'
import * as MAPPINGS_SELECTORS from './mappings/selectors'
import * as QUESTIONS_SELECTORS from './questions/selectors'
import * as SECTION_SELECTORS from './sections/section/selectors'
import * as QUESTION_SELECTORS from './questions/question/selectors'

import {traverseParents, traverseChildIds} from './helpers'



const isQuestion = (contentData) => (id) =>
{
	const questionsData = CONTENT_SELECTORS.getQuestionsData(contentData);
	return !!QUESTIONS_SELECTORS.getQuestionDataById(questionsData)(id);	
}


const getResourceUrl = (contentData) =>
{
	// 1. Check current question for resource url
	const questionsData = CONTENT_SELECTORS.getQuestionsData(contentData);
	const currentQuestionId = CONTENT_SELECTORS.getCurrentQuestionId(contentData);
	const currentQuestionData = QUESTIONS_SELECTORS.getQuestionDataById(questionsData)(currentQuestionId);
	const questionResourceUrl = QUESTION_SELECTORS.getResourceUrl(currentQuestionData);
	if (!!questionResourceUrl) { return questionResourceUrl; }

	// 2. Traverse parents to find resource url
	for (let parentSectionData of traverseParents(contentData)(currentQuestionId))
	{
		const sectionResourceUrl = SECTION_SELECTORS.getResourceUrl(parentSectionData);
		if (!!sectionResourceUrl) { return sectionResourceUrl; }
	}

	return CONTENT_SELECTORS.getResourceUrl(contentData);
}


const getPaperPartId = (contentData) => (questionId) =>
{
	for (let parentSectionData of traverseParents(contentData)(questionId))
	{
		const isOneWay = SECTION_SELECTORS.isOneWay(parentSectionData);
		if (isOneWay) { return SECTION_SELECTORS.getSectionId(parentSectionData); }
	}
}

const hasPaperPartUnansweredQuestions = (contentData) => (id) =>
{
	const questionsData = CONTENT_SELECTORS.getQuestionsData(contentData);
	const _getQuestionData = QUESTIONS_SELECTORS.getQuestionDataById(questionsData);

	for (let childId of traverseChildIds(contentData)(id))
	{
		if (!isQuestion(contentData)(childId)) { continue; }
		const questionData = _getQuestionData(childId);
		if (!QUESTION_SELECTORS.isAnswered(questionData)) { return true; }
	}

	return false;
}

const getNextPaperPartId = (contentData) => (id) =>
{
	const mappingsData = CONTENT_SELECTORS.getMappingsData(contentData);
	const parentSectionId = MAPPINGS_SELECTORS.getParentSectionId(mappingsData)(id);
	const siblingIds = MAPPINGS_SELECTORS.getChildrenForId(mappingsData)(parentSectionId);
	const nextIndex = siblingIds.indexOf(id) + 1;
	return nextIndex === siblingIds.length ? null : siblingIds[nextIndex];
}

const getFirstQuestionIdInSection = (contentData) => (id) =>
{
	const mappingsData = CONTENT_SELECTORS.getMappingsData(contentData);
	const childIds = MAPPINGS_SELECTORS.getChildrenForId(mappingsData)(id);
	const firstId = childIds[0];
	if (isQuestion(contentData)(firstId)) { return firstId; }
	return getFirstQuestionIdInSection(contentData)(firstId);
}

const hasSamePaperPart = (contentData) => (id1, id2) =>
{
	const paperPartId1 = getPaperPartId(contentData)(id1);
	const paperPartId2 = getPaperPartId(contentData)(id2);
	return paperPartId1 === paperPartId2;
}


const getQuestionSectionText = (contentData) => (questionId) =>
{
	for (let parentSectionData of traverseParents(contentData)(questionId))
	{
		const sectionText = SECTION_SELECTORS.getSectionText(parentSectionData);
		if (sectionText) { return sectionText; }
	}
}


const getQuestionScenarioText = (contentData) => (questionId) =>
{
	for (let parentSectionData of traverseParents(contentData)(questionId))
	{
		const scenarioText = SECTION_SELECTORS.getScenarioText(parentSectionData);
		if (scenarioText) { return scenarioText; }
	}
}


const getQuestionNoInSection = (contentData) => (sectionId, questionId) =>
{
	let questionNumber = 0;

	for (let childId of traverseChildIds(contentData)(sectionId)) {
		if (isQuestion(contentData)(childId)) { questionNumber++; }
		if (childId === questionId) { return questionNumber; }
	}

	throw "Question doesn't appear to be contained in this section";
}


const getQuestionCountInSection = (contentData) => (sectionId) =>
{
	let count = 0;

	for (let childId of traverseChildIds(contentData)(sectionId)) {
		if (isQuestion(contentData)(childId)) { count++; }
	}

	return count;
}


const getSiblingQuestionId = (contentData) =>
{
	const currentQuestionId = CONTENT_SELECTORS.getCurrentQuestionId(contentData);
	const questionsData = CONTENT_SELECTORS.getQuestionsData(contentData);
	const getQuestionDataById = QUESTIONS_SELECTORS.getQuestionDataById(questionsData);
	const getQuestionDataByNumber = QUESTIONS_SELECTORS.getQuestionDataByNumber(questionsData);

	return (questionId=currentQuestionId, direction) => {
		const questionData = getQuestionDataById(questionId);
		const questionNumber = QUESTION_SELECTORS.getNumber(questionData);
		const questionCount = QUESTIONS_SELECTORS.getQuestionCount(questionsData);

		let siblingNumber = questionNumber + direction;
		const {isDisabled, getId} = QUESTION_SELECTORS;

		while (siblingNumber > 0 && siblingNumber <= questionCount) {
			const siblingData = getQuestionDataByNumber(siblingNumber);
			const siblingId = getId(siblingData);

			if (!hasSamePaperPart(contentData)(questionId, siblingId)) { return undefined; }
			if (!isDisabled(siblingData)) { return siblingId; }

			siblingNumber += direction;
		}
	}
}


const getNextQuestionId = (contentData, questionId) => {
	return getSiblingQuestionId(contentData)(questionId, 1);
}


const getPreviousQuestionId = (contentData, questionId) => {
	return getSiblingQuestionId(contentData)(questionId, -1);
}


const getResumedQuestionId = (contentData) =>
{
	const questionsData = CONTENT_SELECTORS.getQuestionsData(contentData);
	const resumedNumber = CONTENT_SELECTORS.getResumedQuestionNumber(contentData);
	const getQuestionDataByNumber = QUESTIONS_SELECTORS.getQuestionDataByNumber(questionsData);

	const resumedQuestionData = getQuestionDataByNumber(resumedNumber);
	const resumedId = QUESTION_SELECTORS.getId(resumedQuestionData);
 
	const questionDisabled = QUESTION_SELECTORS.isDisabled(resumedQuestionData);
	return questionDisabled ? getNextQuestionId(contentData, resumedId) : resumedId;
}


const hasUnansweredQuestions = (contentData) =>
{
	const questionsData = CONTENT_SELECTORS.getQuestionsData(contentData);
	const unansweredQuestion = questionsData.find(questionData => {
		return !QUESTION_SELECTORS.isAnswered(questionData) && !QUESTION_SELECTORS.isDisabled(questionData);
	});
	return !!unansweredQuestion;
}


const requiresWorkings = (contentData, questionId) =>
{
	const questionsData = CONTENT_SELECTORS.getQuestionsData(contentData);
	const questionData = QUESTIONS_SELECTORS.getQuestionDataById(questionsData)(questionId);
	if (QUESTION_SELECTORS.requiresWorkings(questionData)) {
		return true;
	}

	for (let parentSectionData of traverseParents(contentData)(questionId))
	{
		const hasWorkings = SECTION_SELECTORS.requiresWorkings(parentSectionData);
		if (hasWorkings) { return hasWorkings; }
	}

	return false;
}


const requiresCalculator = (contentData, questionId) =>
{
	const questionsData = CONTENT_SELECTORS.getQuestionsData(contentData);
	const questionData = QUESTIONS_SELECTORS.getQuestionDataById(questionsData)(questionId);

	if (QUESTION_SELECTORS.requiresCalculator(questionData)) {
		return true;
	}

	for (let parentSectionData of traverseParents(contentData)(questionId)) {
		const hasCalculator = SECTION_SELECTORS.requiresCalculator(parentSectionData);
		if (hasCalculator) { return hasCalculator; }
	}

	return false;
}


const getReadingTimeRemaining = (contentData, questionId) =>
{
	for (let parentSectionData of traverseParents(contentData)(questionId)) {
		const readingTimeRemaining = SECTION_SELECTORS.getReadingTimeRemaining(parentSectionData);
		if (check.assigned(readingTimeRemaining)) { return readingTimeRemaining; }
	}
}

const getAnsweringTimeRemaining = (contentData, questionId) =>
{
	for (let parentSectionData of traverseParents(contentData)(questionId)) {
		const answeringTimeRemaining = SECTION_SELECTORS.getSectionTimeRemaining(parentSectionData);
		if (check.assigned(answeringTimeRemaining)) { return answeringTimeRemaining; }
	}
}

const getReadingTime = (contentData, questionId) =>
{
	for (let parentSectionData of traverseParents(contentData)(questionId)) {
		const readingTime = SECTION_SELECTORS.getReadingTime(parentSectionData);
		if (check.assigned(readingTime)) { return readingTime; }
	}
}

const getAnsweringTime = (contentData, questionId) =>
{
	for (let parentSectionData of traverseParents(contentData)(questionId)) {
		const answeringTime = SECTION_SELECTORS.getSectionTime(parentSectionData);
		if (check.assigned(answeringTime)) { return answeringTime; }
	}
}

const getWarningTime = (contentData, questionId) =>
{
	for (let parentSectionData of traverseParents(contentData)(questionId)) {
		const warningTime = SECTION_SELECTORS.getWarningTime(parentSectionData);
		if (check.assigned(warningTime)) { return warningTime; }
	}
}


export {
	isQuestion,
	getResourceUrl,
	getQuestionSectionText,
	getQuestionScenarioText,
	getQuestionNoInSection,
	getNextQuestionId,
	getPreviousQuestionId,
	getResumedQuestionId,
	hasUnansweredQuestions,
 
	getPaperPartId,
	hasPaperPartUnansweredQuestions,
	getNextPaperPartId,
	getFirstQuestionIdInSection,
	getQuestionCountInSection,
	hasSamePaperPart,
	requiresWorkings,
	requiresCalculator,

	getReadingTime,
	getReadingTimeRemaining,
	getAnsweringTime,
	getAnsweringTimeRemaining,
	getWarningTime
}