
// npm
import React from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'

// xams-utils
import {check} from '@xams-utils/check-types'

// redux
import {addSchedule} from './actions'
import {getUserSessionData} from 'redux/reducers/session/selectors'
import {getUserGuid} from 'redux/reducers/session/user/selectors'
import {getSessionData} from 'redux/reducers/selectors'
import {getSettingsData} from 'redux/reducers/selectors'
import {getClientSettingsData} from 'redux/reducers/settings/selectors'
import {getProctoringMode} from 'redux/reducers/settings/client/selectors'
import {getProctoringType} from 'redux/reducers/settings/client/selectors'

// utils
import {COOKIE_NAMES, cookies} from 'utils/cookies'
import {assessmentApi} from 'libs/api/interface/api-assessment'
import {sessionStorageApi, KEYS} from 'libs/browser_storage/session-storage-api'



// ScheduleDataFetcher (not connected to store)
// --------------------------------------------

class ScheduleDataFetcher extends React.Component
{
	componentDidMount()
	{
		const schedule = this.getScheduleFromQueryString() ||
										 this.getScheduleFromSessionStorage();

		if (schedule) {
			this.props.saveSchedules([schedule]);
			this.props.onSuccess();
		}
		else {
			const onSuccess = (response) => {
				const parsedResponse = JSON.parse(response);
				this.props.saveSchedules(parsedResponse);
				this.props.onSuccess();
			}

			assessmentApi.getExamSchedule(this.props.userGuid, this.props.proctoringMode)
			.then(onSuccess, this.props.onFail);
		}
	}

	render()
	{
		return null;
	}

	getScheduleFromQueryString()
	{
		const urlParameters = new URLSearchParams(window.location.search);
		const externalSessionData = urlParameters.get('x');
		if (!externalSessionData) { return; }

		const parsedSessionData = JSON.parse(externalSessionData);
		const {schedule, exam} = parsedSessionData;

		sessionStorageApi.saveDataTo(KEYS.EXTERNAL_EXAM, exam);
		sessionStorageApi.saveDataTo(KEYS.EXTERNAL_SCHEDULE, schedule || {});

		return this.getScheduleFromExternalData(parsedSessionData);
	}

	getScheduleFromSessionStorage()
	{
		const externalScheduleData = sessionStorageApi.retrieveDataFrom(KEYS.EXTERNAL_SCHEDULE);
		const externalExamData = sessionStorageApi.retrieveDataFrom(KEYS.EXTERNAL_EXAM);
		if (check.emptyObject(externalExamData)) { return; }

		return this.getScheduleFromExternalData({
			schedule: externalScheduleData,
			exam: externalExamData
		});
	}

	getScheduleFromExternalData(externalDataObject) // externalDataObject may be parsed from querystring or sessionstorage
	{
		let {schedule, exam} = externalDataObject;
		if (schedule === null) { schedule = {}; }

		return {
			guid: schedule.guid || null,
			scheduleType: schedule.scheduleType || null,
			examName: schedule.examName,
			objectGUID: exam.guid,
			objectTypeGUID: exam.objectTypeGuid,
			version: exam.version,
			proctorProvider: schedule.proctorProvider || null,
			proctoringSessionID: schedule.proctoringSessionID || null,
			objectID: schedule.objectID || null,
			qualificationID: schedule.qualificationID  || null,			
			requiresPassword: this.getRequiresPassword(schedule)
		}
	}

	getRequiresPassword(schedule)
	{
		//debugger
		if (schedule.requiresPassword == null || schedule.requiresPassword == false) return false; //no password
		//if proctored and record and review, password not required
		if (this.props.proctoringMode != null && this.props.proctoringType == 1) return false;
		return schedule.requiresPassword;
	}
}

ScheduleDataFetcher.propTypes = {
	userGuid: PropTypes.string.isRequired,
	saveSchedules: PropTypes.func.isRequired,
	onSuccess: PropTypes.func.isRequired,
	onFail: PropTypes.func.isRequired
}


// ScheduleDataFetcher (connected to store)
// --------------------------------------------

const mapStoreToProps = (store) => ({
	userGuid: getUserGuid(getUserSessionData(getSessionData(store))),
	proctoringMode: getProctoringMode(getClientSettingsData(getSettingsData(store))),
	proctoringType: getProctoringType(getClientSettingsData(getSettingsData(store)))
});

const mapDispatchToProps = (dispatch) => ({
	saveSchedules: (response) => {
		response.forEach(item => {
			dispatch(addSchedule(item));
		});
	}
});

ScheduleDataFetcher = connect(mapStoreToProps, mapDispatchToProps)(ScheduleDataFetcher);


export {ScheduleDataFetcher}