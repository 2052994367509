// npm
import React, { Component } from 'react';

// material-ui
import withStyles from '@material-ui/core/styles/withStyles';

// react
import { Align } from 'components/layout/align';
import CalculatorButtonRow from './calculator-button-row';
import { CalculatorDisplay } from './calculator-display';
import {isSEB, removeCalculatorSymbols} from './calculator-helper'

import check from 'check-types';

import {
  isOperand,
  isOperator,
  getResult,
  plusMinusValue,
  spaceOutResult,
  decimalPointAllowed,
  operatorAllowed
} from './calculator-helper';

// constants
import { CALCULATOR_BUTTONS} from './constants';

// Calculator (not connected to styles)
// ----------------------------------------------------------------------------


class Calculator extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: ''
    };

    this.justProcessedAnswer = false;
    this.maxLength = -1;
    this.handleButtonClick = this.handleButtonClick.bind(this);
  }

  handleButtonClick(_button) {
    const { value: _value } = this.state;
    const { evaluationType } = this.props;

    let value = removeCalculatorSymbols(_value);
    const button = removeCalculatorSymbols(_button);
    
    if (isOperand(button) && this.justProcessedAnswer) {
      value = button;
      this.justProcessedAnswer = false;
    } else if (
      isOperand(button) ||
      (isOperator(button) && operatorAllowed(button, value))
    ) {
      if (button !== '.' || decimalPointAllowed(value)) {
        if (this.maxLength ===-1 || value.length < this.maxLength) {
          value += button;
          this.justProcessedAnswer = false;
        }
      }
    } else if (button === '=') {
      const result = getResult(value, evaluationType);

      if (check.assigned(result)) {
        this.props.onResult(spaceOutResult('' + value, result, button));
        value = '' + result;
        this.justProcessedAnswer = true;
      }
    } else if (button === 'C') {
      value = '';
      this.justProcessedAnswer = false;
    } else if (button === '<') {
      value = value.slice(0, -1);
      this.justProcessedAnswer = false;
    } else if (button === '@' && (this.maxLength === -1 || value.length < this.maxLength)) {
      value = plusMinusValue(value);
    }

    this.setState({ value });
  }

  getCalculatorButtons() {
    return CALCULATOR_BUTTONS.map((buttonRow, index) => (
      <CalculatorButtonRow
        key={index}
        buttons={buttonRow}
        onSelect={this.handleButtonClick}
      />
    ));
  }

  render() {
    const { classes } = this.props;
    const { value } = this.state;
    const displayProps = {
      value
    };
    const className = `${classes.container} ${isSEB() && classes.seb}`;
    return (
      <div className={className}>
        <Align>
          <div>
            {/* <div>{navigator.userAgent}</div> */}
            <CalculatorDisplay {...displayProps} />
          {this.getCalculatorButtons(this.handleButtonClick)}
          </div>
        </Align>
      </div>
    );
  }
}

// Calculator (connected to styles)
// ----------------------------------------------------------------------------

const styles = ({ palette, spacing }) => ({
  container: {
    borderWidth: 1,
    boxSizing: 'border-box',
    borderStyle: 'solid',
    borderColor: palette.background.contrastText,
    borderRadius: 8,
    flexShrink: 0,
    margin: spacing.unit,
    marginTop: 0
  },
  seb:{
    marginBottom: spacing.unit * 8
  }
});

Calculator = withStyles(styles)(Calculator);

// EXPORT
// ----------------------------------------------------------------------------
export { Calculator };
