
// npm
import React from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'

// react
import {MultiImagePreloader} from 'components/functional/image_preloading/multi-image-preloader'

// redux (selectors)
import {getSettingsData} from 'redux/reducers/selectors'
import {getClientSettingsData} from 'redux/reducers/settings/selectors'
import {getLogoUrl, getAppBarLogoUrl} from 'redux/reducers/settings/client/selectors'


// LogoPreloader (not connected to store)
// ----------------------------------------------

let LogoPreloader = (props) =>
{
	const {onSuccess, onFail} = props;
	const urls = [props.logoUrl, props.appBarLogoUrl];
	return <MultiImagePreloader urls={urls} onSuccess={onSuccess} onFail={onFail}/>;
}

LogoPreloader.propTypes = {
	onSuccess: PropTypes.func.isRequired,
	onFail: PropTypes.func.isRequired,
	logoUrl: PropTypes.string.isRequired,
	appBarLogoUrl: PropTypes.string.isRequired
}


// LogoPreloader (connected to store)
// ----------------------------------------------

const mapStoreToProps = (store) =>
{
	const clientSettingsData = getClientSettingsData(getSettingsData(store));

	return {
		appBarLogoUrl: getAppBarLogoUrl(clientSettingsData),
		logoUrl: getLogoUrl(clientSettingsData)
	}
}

LogoPreloader = connect(mapStoreToProps)(LogoPreloader);


// Export
// ----------------------------------------------
export {LogoPreloader}